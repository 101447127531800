<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="load">
              <b-row>
                <b-col lg="12" sm="12">
                  <b-row>
                    <b-col>
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                            <!-- {{ $t('elearning_tpm.course_managment') + ' ' + $t('globalTrans.details') }} -->
                            {{ $i18n.locale === 'bn' ? item.training_title_bn : item.training_title }}
                            <p>{{ trainingStartDate }} {{ '-' }} {{ trainingEndDate }}</p>
                        </list-report-head>
                    </b-col>
                  </b-row>
                  <b-row>
                    <div class="col-md-12" v-for="(item,index) in formData" :key="index">
                      <b-table-simple class="evaluation-table" borderless style="width:100%">
                        <b-tr>
                          <b-td style="width:85%"><div class="pt-0"><span style="font-weight: bold;">{{$t('globalTrans.date')}}</span> : {{ item.training_date | dateFormat }}</div></b-td>
                          <b-td style="width:15%" class="text-right"><div class="pb-0"></div></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td style="width:85%"><div class="pt-0"><span style="font-weight: bold;">{{$t('elearning_tpm.course_name')}}</span> : {{ $i18n.locale === 'bn' ? item.topics_session_bn : item.topics_session }}</div></b-td>
                          <b-td style="width:15%" class="text-right"><div class="pb-0"><span style="font-weight: bold;">{{ $t('elearning_tim.evaluation') }}</span>: {{ $n(item.avg_mark) }}</div></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><div class="pb-0"><span style="font-weight: bold;">{{ $t('elearning_tim.trainer_name') }}</span>: {{ ($i18n.locale === 'bn' ? item.name_bn : item.name) + ', ' }} {{ getDesignationData(item, 'admin') + ', ' }} {{ getOrgData(item, 'admin') }}</div></b-td>
                          <b-td><div class="pb-0 text-right"><span style="font-weight: bold;">{{ $t('globalTrans.average') }}</span>: {{ $n(avgCalculation(item.avg_mark)) }}</div></b-td>
                        </b-tr>
                      </b-table-simple>
                      <b-table-simple class="evaluation-table" borderless style="width:100%;margin-top: 0px;padding-top: 0px;">
                        <b-tr>
                          <b-td style="width:65%">
                              <div class="pb-0"><span style="font-weight: bold;">{{ $t('elearning_config.total_trainee') }}</span> : {{ $n(item.total_applicant) + ', ' }} <span style="font-weight: bold;">{{ $t('elearning_tim.attend') }}</span> : {{ $n(item.total_attend_person) + ', ' }} <span style="font-weight: bold;">{{ $t('elearning_tim.evaluation') }}</span>: {{ $n(item.total_evaluation_person) }}</div>
                            </b-td>
                          <b-td style="width:35%">
                            <a href="javascript:" v-b-modal.modal-trainee @click="edit(item)">
                              {{ $t('elearning_tim.please_click_here_details') }}
                            </a>
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                      <hr style="margin-top: 5px;margin-bottom: 10px;"/>
                      <div class="d-flex" id="eval" v-for="(itm,index1) in item.evalution" :key="index1">
                        <div class="col-sm-6 pb-0">
                          <span>{{$n(index1+1)}}{{ '.' }} {{ $i18n.locale === 'bn' ? itm.trainer_evaluation_name_bn : itm.trainer_evaluation_name }}</span>
                        </div>
                        <div class="col-sm-6 pb-0">
                          <b-table-simple class="evaluation-table" bordered style="width:100%">
                            <b-tr class="text-center">
                              <b-td style="width: 60px;">
                                <span v-if="itm.avg_mark >= 1 && itm.avg_mark < 2"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                <span v-else>{{ $i18n.locale === 'bn' ? '১' : '1' }}</span>
                              </b-td>
                              <b-td style="width: 60px;">
                                <span v-if="itm.avg_mark >= 2 && itm.avg_mark < 3"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                <span v-else>{{ $i18n.locale === 'bn' ? '২' : '2' }}</span>
                              </b-td>
                              <b-td style="width: 60px;">
                                <span v-if="itm.avg_mark >= 3 && itm.avg_mark < 4"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                <span v-else>{{ $i18n.locale === 'bn' ? '৩' : '3' }}</span>
                              </b-td>
                              <b-td style="width: 60px;">
                                <span v-if="itm.avg_mark >= 4 && itm.avg_mark < 5"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                <span v-else>{{ $i18n.locale === 'bn' ? '৪' : '4' }}</span>
                              </b-td>
                              <b-td style="width: 60px;">
                                <span v-if="itm.avg_mark === 5"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                                <span v-else>{{ $i18n.locale === 'bn' ? '৫' : '5' }}</span>
                              </b-td>
                            </b-tr>
                          </b-table-simple>
                      </div>
                      </div>
                    </div>
                  </b-row>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-trainee" size="lg" :title="$t('elearning_tim.trainee_evaluation') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <!-- <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
        {{  $t('globalTrans.export_pdf') }}
      </b-button> -->
      <TraineeDetails :item="editItemId" :key="editItemId"  ref="details"/>
    </b-modal>
  </b-container>
</template>
<style>
.evaluation-table td{
  padding: 0px !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import { trainerEvaluationData, allTrainerData, trainerEvaluationDataWord } from '../../api/routes'
import TraineeDetails from './TraineeDetails'

export default {
  components: {
    ListReportHead,
    TraineeDetails
  },
  props: ['item'],
  created () {
    if (this.item) {
      this.getEvaluationData()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      load: false,
      formData: {
        id: '',
        name: '',
        email: '',
        avg_mark: 0
      },
      designationName: '',
      organizationName: '',
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      circularInfo: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      trainerListData: [],
      dateErrorMsg: '',
      trainingStartDate: '',
      trainingEndDate: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: [],
      editItemId: []
    }
  },
  computed: {
    trainerList: function () {
      const listObject = this.trainerListData
      return listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
        }
      })
    },
    monthList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'জানুয়ারি' : 'January',
          text_en: 'January',
          text_bn: 'জানুয়ারি'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'ফেব্রুয়ারি' : 'February',
          text_en: 'February',
          text_bn: 'ফেব্রুয়ারি'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'মার্চ' : 'March',
          text_en: 'March',
          text_bn: 'মার্চ'
        },
        {
          value: 4,
          text: this.$i18n.locale === 'bn' ? 'এপ্রিল' : 'April',
          text_en: 'April',
          text_bn: 'এপ্রিল'
        },
        {
          value: 5,
          text: this.$i18n.locale === 'bn' ? 'মে' : 'May',
          text_en: 'May',
          text_bn: 'মে'
        },
        {
          value: 6,
          text: this.$i18n.locale === 'bn' ? 'জুন' : 'June',
          text_en: 'June',
          text_bn: 'জুন'
        },
        {
          value: 7,
          text: this.$i18n.locale === 'bn' ? 'জুলাই' : 'July',
          text_en: 'July',
          text_bn: 'জুলাই'
        },
        {
          value: 8,
          text: this.$i18n.locale === 'bn' ? 'আগস্ট' : 'August',
          text_en: 'August',
          text_bn: 'আগস্ট'
        },
        {
          value: 9,
          text: this.$i18n.locale === 'bn' ? 'সেপ্টেম্বর' : 'September',
          text_en: 'September',
          text_bn: 'সেপ্টেম্বর'
        },
        {
          value: 10,
          text: this.$i18n.locale === 'bn' ? 'অক্টোবর' : 'October',
          text_en: 'October',
          text_bn: 'অক্টোবর'
        },
        {
          value: 11,
          text: this.$i18n.locale === 'bn' ? 'নভেম্বর' : 'November',
          text_en: 'November',
          text_bn: 'নভেম্বর'
        },
        {
          value: 12,
          text: this.$i18n.locale === 'bn' ? 'ডিসেম্বর' : 'December',
          text_en: 'December',
          text_bn: 'ডিসেম্বর'
        }
      ]
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    edit (item) {
      this.editItemId = item
    },
    getDesignationData (item, type) {
      if (parseInt(item.not_here_designation) === 0 && parseInt(item.profession_type) === 1) {
        let desigObj
        if (type === 'admin') {
          desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === item.profession_designation_id)
        }
        if (type === 'panel') {
          desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === item.profession_designation_id)
        }
        if (typeof desigObj !== 'undefined') {
          this.designationName = this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
          return this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
        } else {
          return ''
        }
      } else {
        this.designationName = this.$i18n.locale === 'en' ? item.profession_designation_en : item.profession_designation_bn
        return this.$i18n.locale === 'en' ? item.profession_designation_en : item.profession_designation_bn
      }
    },
    getOrgData (item, type) {
      if (item.profession_org_id && parseInt(item.profession_type) === 1) {
        let orgObj
        if (type === 'admin') {
          orgObj = this.$store.state.commonObj.organizationProfileList.find(designation => designation.value === item.profession_org_id)
        }
        if (type === 'panel') {
          orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(designation => designation.value === item.profession_org_id)
        }
        if (typeof orgObj !== 'undefined') {
          this.organizationName = this.$i18n.locale === 'en' ? orgObj.text_en : orgObj.text_bn
          return this.$i18n.locale === 'en' ? orgObj.text_en : orgObj.text_bn
        } else {
          return ''
        }
      } else {
        this.organizationName = this.$i18n.locale === 'en' ? item.profession_other_org_name : item.profession_other_org_name_bn
        return this.$i18n.locale === 'en' ? item.profession_other_org_name : item.profession_other_org_name_bn
      }
    },
    async getOrgTrainerList () {
      this.trainerLoading = true
      const params = {
        org_id: this.search.org_id,
        profession_type: this.search.profession_type
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, allTrainerData, params)
      if (!result.success) {
        this.trainerListData = []
      } else {
        this.trainerListData = result.data
      }
      this.trainerLoading = false
    },
    avgCalculation (data) {
      return parseFloat(data * 100 / 5).toFixed(2)
    },
    getTrainerEvaluation (id) {
      const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.find(doc => doc.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
        return trainingTitleObj !== undefined ? trainingTitleObj.text_bn : ''
      } else {
        return trainingTitleObj !== undefined ? trainingTitleObj.text_en : ''
      }
    },
    getTrainerName (id) {
      const trainerName = this.trainerList.find(doc => doc.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainerName !== undefined ? trainerName.text_bn : ''
      } else {
        return trainerName !== undefined ? trainerName.text_en : ''
      }
    },
    // getFormData () {
    //   const tmpData = this.$store.state.list.find(item => item.id === this.item.id)
    //   return JSON.parse(JSON.stringify(tmpData))
    // }
    async getEvaluationData () {
        this.load = true
        const search = {
          circular_memo_no: this.item.circular_memo_no,
          batch_no: this.item.batch_no
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerEvaluationData, search)
        if (!result.success) {
          this.formData = []
        } else {
            this.formData = result.data
            this.circularInfo = result.circularInfo
            const startDate = this.circularInfo.training_start_date.split('-')
            const endDate = this.circularInfo.training_end_date.split('-')
            const sMonth = startDate[1]
            const eMonth = endDate[1]
            const sMonthName = this.monthList.find(doc => doc.value === parseInt(sMonth))
            const eMonthName = this.monthList.find(doc => doc.value === parseInt(eMonth))
            if (parseInt(startDate[2]) < 10) {
              this.trainingStartDate = this.$n(0) + this.$n(startDate[2], { useGrouping: false }) + ' ' + (this.currentLocale === 'en' ? sMonthName.text_en : sMonthName.text_bn) + ' ' + this.$n(startDate[0], { useGrouping: false })
            } else {
              this.trainingStartDate = this.$n(startDate[2], { useGrouping: false }) + ' ' + (this.currentLocale === 'en' ? sMonthName.text_en : sMonthName.text_bn) + ' ' + this.$n(startDate[0], { useGrouping: false })
            }
            if (parseInt(endDate[2]) < 10) {
              this.trainingEndDate = this.$n(0) + this.$n(endDate[2], { useGrouping: false }) + ' ' + (this.currentLocale === 'en' ? eMonthName.text_en : eMonthName.text_bn) + ' ' + this.$n(endDate[0], { useGrouping: false })
            } else {
              this.trainingEndDate = this.$n(endDate[2], { useGrouping: false }) + ' ' + (this.currentLocale === 'en' ? eMonthName.text_en : eMonthName.text_bn) + ' ' + this.$n(endDate[0], { useGrouping: false })
            }
        }
        this.load = false
    },
    async pdfExport () {
      const search = {
        role: this.$store.state.Auth.activeRoleId,
        circular_memo_no: this.item.circular_memo_no,
        batch_no: this.item.batch_no,
        training_title: this.item.training_title,
        training_title_bn: this.item.training_title_bn,
        trainingStartDate: this.trainingStartDate,
        trainingEndDate: this.trainingEndDate,
        organizationName: this.organizationName,
        designationName: this.designationName,
        org_id: 12
      }
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale }, search)
      this.load = true
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, trainerEvaluationData, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.load = false
    },
    async pdfDownload () {
      const search = {
        role: this.$store.state.Auth.activeRoleId,
        circular_memo_no: this.item.circular_memo_no,
        batch_no: this.item.batch_no,
        training_title: this.item.training_title,
        training_title_bn: this.item.training_title_bn,
        trainingStartDate: this.trainingStartDate,
        trainingEndDate: this.trainingEndDate,
        organizationName: this.organizationName,
        designationName: this.designationName,
        org_id: 12
      }
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale }, search)
      this.load = true
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, trainerEvaluationData, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      // Create a temporary link element
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      // Set the file name for the download
      link.download = 'resourcePersonEvaluation.pdf'
      // Trigger the download
      document.body.appendChild(link)
      link.click()
      // Clean up
      document.body.removeChild(link)
      this.load = false
    },
    async wordExport () {
      const search = {
        role: this.$store.state.Auth.activeRoleId,
        auth_org: this.$store.state.Auth.authUser.org_id,
        circular_memo_no: this.item.circular_memo_no,
        batch_no: this.item.batch_no,
        training_title: this.item.training_title,
        training_title_bn: this.item.training_title_bn,
        trainingStartDate: this.trainingStartDate,
        trainingEndDate: this.trainingEndDate,
        organizationName: this.organizationName,
        designationName: this.designationName,
        org_id: 12
      }
      const params = Object.assign({ request_type: 'word', local: this.$i18n.locale }, search)
      this.load = true
      try {
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, trainerEvaluationDataWord, params)
              // Create a Blob and object URL
              var blob = new Blob([result], {
          type: 'application/msword' // Use 'application/msword' for Word documents
        })

        var url = window.URL.createObjectURL(blob)

        // Create a temporary link element to trigger the download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'speaker_evaluation.docx' // Set the desired filename with .docx extension for Word documents

        // Trigger the download and cleanup
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        this.load = false
      } catch (error) {
        // Handle any errors here
      } finally {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
  #eval{
    display: inline-block;
  }
</style>
