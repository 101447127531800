<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="load">
              <b-row>
                <b-col lg="12" sm="12">
                  <b-row>
                    <b-col>
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                            {{ '' }}
                        </list-report-head>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-table-simple class="evaluation-table" borderless style="width:100%; margin:15px;">
                      <b-tr>
                        <b-td style="width:20%">{{$t('elearning_iabm.circular_memo_no')}}</b-td>
                        <b-td style="width:2%">{{ ':' }}</b-td>
                        <b-td style="width:78%">{{ formData.circular_memo_no }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td style="width:20%">{{$t('elearning_config.training_title')}}</b-td>
                        <b-td style="width:2%">{{ ':' }}</b-td>
                        <b-td style="width:78%">{{ getTrainingTitle(formData.training_title_id) }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td style="width:20%">{{$t('elearning_tpm.course_name')}}</b-td>
                        <b-td style="width:2%">{{ ':' }}</b-td>
                        <b-td style="width:78%">{{ $i18n.locale === 'bn' ? formData.topics_session_bn : formData.topics_session }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td style="width:20%">{{$t('elearning_tim.trainer_name')}}</b-td>
                        <b-td style="width:2%">{{ ':' }}</b-td>
                        <b-td style="width:78%">{{ ($i18n.locale === 'bn' ? formData.name_bn : formData.name) + ', ' }} {{ getDesignationData(formData, 'admin') + ', ' }} {{ getOrgData(formData, 'admin') }}</b-td>
                      </b-tr>
                    </b-table-simple>
                  </b-row>
                  <b-row>
                    <b-table-simple bordered style="width:100%; margin:15px;">
                      <b-tr>
                        <b-th class="text-center" style="width:12%">{{$t('globalTrans.sl_no')}}</b-th>
                        <b-th class="text-center" style="width:28%">{{$t('elearning_tim.trainee_name')}}</b-th>
                        <b-th class="text-center" style="width:20%">{{$t('elearning_tim.designation')}}</b-th>
                        <b-th class="text-center" style="width:20%">{{$t('globalTrans.organization')}}</b-th>
                        <b-th class="text-center" style="width:10%">{{$t('elearning_tim.attend')}}</b-th>
                        <b-th class="text-center" style="width:10%">{{$t('elearning_tim.evaluation')}}</b-th>
                      </b-tr>
                      <b-tr v-for="(item,index) in trainerData" :key="index">
                        <b-td class="text-center">{{ $n(index+1) }}</b-td>
                        <b-td class="text-center">{{ $i18n.locale === 'bn' ? item.name_bn : item.name }}</b-td>
                        <b-td class="text-center">{{ getDesignationData(item, 'admin') }}</b-td>
                        <b-td class="text-center">{{ getOrgData(item, 'admin') }}</b-td>
                        <b-td class="text-center">{{ parseInt(item.attend) === parseInt(1) ? $t('globalTrans.yes') : $t('globalTrans.no') }}</b-td>
                        <b-td class="text-center">{{ parseInt(item.evaluation) === parseInt(1) ? $t('globalTrans.yes') : $t('globalTrans.no') }}</b-td>
                      </b-tr>
                    </b-table-simple>
                  </b-row>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.evaluation-table td{
  padding: 0px !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import { trainerEvaluationData, evaluationTraineeData } from '../../api/routes'

export default {
  components: {
    ListReportHead
  },
  props: ['item'],
  created () {
    if (this.item) {
      this.formData = this.item
      this.getEvaluationData()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      load: false,
      formData: {},
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainerData: [],
      trainingTitleList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getDesignationData (item, type) {
      if (parseInt(item.not_here_designation) === 0 && parseInt(item.profession_type) === 1) {
        let desigObj
        if (type === 'admin') {
          desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === item.profession_designation_id)
        }
        if (type === 'panel') {
          desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === item.profession_designation_id)
        }
        if (typeof desigObj !== 'undefined') {
          this.designationName = this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
          return this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
        } else {
          return ''
        }
      } else {
        this.designationName = this.$i18n.locale === 'en' ? item.profession_designation_en : item.profession_designation_bn
        return this.$i18n.locale === 'en' ? item.profession_designation_en : item.profession_designation_bn
      }
    },
    getOrgData (item, type) {
      if (item.profession_org_id && parseInt(item.profession_type) === 1) {
        let orgObj
        if (type === 'admin') {
          orgObj = this.$store.state.commonObj.organizationProfileList.find(designation => designation.value === item.profession_org_id)
        }
        if (type === 'panel') {
          orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(designation => designation.value === item.profession_org_id)
        }
        if (typeof orgObj !== 'undefined') {
          this.organizationName = this.$i18n.locale === 'en' ? orgObj.text_en : orgObj.text_bn
          return this.$i18n.locale === 'en' ? orgObj.text_en : orgObj.text_bn
        } else {
          return ''
        }
      } else {
        this.organizationName = this.$i18n.locale === 'en' ? item.profession_other_org_name : item.profession_other_org_name_bn
        return this.$i18n.locale === 'en' ? item.profession_other_org_name : item.profession_other_org_name_bn
      }
    },
    avgCalculation (data) {
      return parseFloat(data * 100 / 5).toFixed(2)
    },
    getTrainerEvaluation (id) {
      const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.find(doc => doc.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
        return trainingTitleObj !== undefined ? trainingTitleObj.text_bn : ''
      } else {
        return trainingTitleObj !== undefined ? trainingTitleObj.text_en : ''
      }
    },
    getTrainingTitle (id) {
      const trainerName = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainerName !== undefined ? trainerName.text_bn : ''
      } else {
        return trainerName !== undefined ? trainerName.text_en : ''
      }
    },
    // getFormData () {
    //   const tmpData = this.$store.state.list.find(item => item.id === this.item.id)
    //   return JSON.parse(JSON.stringify(tmpData))
    // }
    async getEvaluationData () {
        this.load = true
        const search = {
          circular_memo_no: this.item.circular_memo_no,
          batch_no: this.item.batch_no,
          course_id: this.item.course_id,
          trainer_id: this.item.trainer_id,
          training_date: this.item.training_date
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, evaluationTraineeData, search)
        if (!result.success) {
          this.trainerData = []
        } else {
            this.trainerData = result.data
        }
        this.load = false
    },
    async pdfExport () {
      const search = {
        role: this.$store.state.Auth.activeRoleId,
        circular_memo_no: this.item.circular_memo_no,
        batch_no: this.item.batch_no,
        training_title: this.item.training_title,
        training_title_bn: this.item.training_title_bn,
        trainingStartDate: this.trainingStartDate,
        trainingEndDate: this.trainingEndDate,
        organizationName: this.organizationName,
        designationName: this.designationName,
        org_id: 12
      }
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale }, search)
      this.load = true
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, trainerEvaluationData, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.load = false
    }
  }
}
</script>
<style>
</style>
